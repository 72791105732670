<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("user.profile.social.title") }}
        </div>
      </CCol>
      <CCol sm="auto">
        <CDropdown togglerText="AGGIUNGI ACCOUNT" color="primary">
          <CDropdownItem @click.prevent="oauth2('facebook')"
            >Facebook</CDropdownItem
          >
          <CDropdownItem @click.prevent="oauth2('google')"
            >Google</CDropdownItem
          >
        </CDropdown>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <ejs-grid
          :dataSource="dm"
          :allowPaging="false"
          :allowSorting="true"
          ref="grid"
        >
          <e-columns>
            <e-column headerText="Provider" field="provider"></e-column>
            <e-column
              headerText="Data ultimo login"
              field="last_login"
              :template="lastLoginTemplate"
            ></e-column>
            <e-column
              headerText="Attivo dal"
              field="date_joined"
              :template="dataJoinedTemplate"
            ></e-column>
            <e-column headerText="" :template="actionsTemplate"></e-column>
          </e-columns>
        </ejs-grid>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Vue from "vue";
import { Page, Sort } from "@syncfusion/ej2-vue-grids";
import TableActions from "./TableActions";
import TableDate from "./TableDate";
import EventBus from "../../../helpers/EventBus";
import errorResponse from "../../../helpers/error";
import { GetDataManagerNew } from "../../../ds";

export default Vue.extend({
  name: "SocialAccount",

  data() {
    const dm = GetDataManagerNew("auth_social");

    return {
      dm: dm,
      error: errorResponse(),

      lastLoginTemplate: () => {
        return {
          template: {
            extends: TableDate,
            propsData: {
              field: "last_login",
            },
          },
        };
      },

      dataJoinedTemplate: () => {
        return {
          template: {
            extends: TableDate,
            propsData: {
              field: "date_joined",
            },
          },
        };
      },

      actionsTemplate: () => {
        return { template: TableActions };
      },
    };
  },

  provide: {
    grid: [Page, Sort],
  },

  mounted() {
    EventBus.$on("social:remove", this.onRemove);
  },

  beforeDestroy() {
    EventBus.$off("social:remove", this.onRemove);
  },

  methods: {
    oauth2(type) {
      this.$auth.oauth2(type, {});
    },

    removeEmail(id) {
      this.error.reset();
      this.dm
        .remove("", id)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Accout social rimosso con successo",
            color: "success",
            autohide: true,
          });
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    onRemove(item) {
      this.removeEmail(item.id);
    },
  },
});
</script>
