<template>
  <div class="d-flex justify-content-end">
    <dropdown-menu :items="actions" :select="onActionSelect">
      <font-awesome-icon icon="ellipsis-h"
    /></dropdown-menu>
  </div>
</template>

<script>
import EventBus from "../../../helpers/EventBus";
const SOCIAL_ACCOUNT_ACTIONS = [{ id: "DELETE", text: "Rimuovi" }];
export default {
  name: "TableActions",

  computed: {
    actions() {
      return SOCIAL_ACCOUNT_ACTIONS;
    },
  },
  methods: {
    onActionSelect: function (args) {
      const { id } = args.item;

      if (id === "DELETE") {
        if (
          confirm(`Confermi di voler rimoovere questo account social?`) == true
        ) {
          EventBus.$emit("social:remove", this.data);
        }
      }
    },
  },
};
</script>
